
import { defineComponent, computed, ref } from 'vue';
import Slider from '../../components/Slider.vue';
import SliderItem from '../../components/SliderItem.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Slider,
    SliderItem,
  },
  setup() {
    const router = useRouter();
    const items = ref(1);
    return {
      items,
    };
  },
});
